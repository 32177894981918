<template>
  <section class="imgInput">
    <div class="imgInput__container">
      <div class="imgInput__containerPicture">
        <label class="imgInput__profilePhoto" :for="!showCroper ? 'profile' : ''">
          <img class="imgInput__profilePhoto" :src="selectedImage" v-if="selectedImage" alt="Imagen de perfil" />
          <span v-else>Seleccionar foto</span>
        </label>
        <input
          class="imgInput__profileInput"
          type="file"
          ref="imgToCut"
          id="profile"
          accept=".png,.jpg,.jpeg,.heic"
          :disabled="showCroper"
          @change="previewProfileImage"
        />
      </div>
      <p class="imgInput__photoName">{{ pictureName ? pictureName : "Seleccionar foto" }}</p>
    </div>
    <div class="imgInput__cropperContainer" v-if="showCroper">
      <div class="imgInput__cropperImg">
        <img ref="image" />
      </div>
      <div class="imgInput__cropperButtons">
        <button class="imgInput__cropperButton" @click="cancelCrop">Cancelar</button>
        <button class="imgInput__cropperButton imgInput__cropperButton--select" @click="selectCrop">Seleccionar</button>
      </div>
    </div>
  </section>
</template>

<script>
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
export default {
  props: {
    selectedImage: {
      type: String,
      default: null,
    },
    pictureName: {
      type: String,
      default: null,
    },
    changePicture: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      cropper: null,
      showCroper: false,
      fileName: "",
    };
  },
  methods: {
    cancelCrop() {
      this.$refs.imgToCut.value = "";
      this.fileName = "";
      this.cropper = null;
      this.showCroper = false;

      this.changePicture({});
    },
    selectCrop() {
      this.cropper = null;
      this.showCroper = false;
    },
    previewProfileImage(event) {
      if (!event.target.files.length || this.showCroper) {
        return;
      }
      this.showCroper = true;

      this.$nextTick(() => {
        this.initCropper();
      });

      const file = event.target.files[0];
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        this.fileName = file.name;
        this.cropper.replace(reader.result);
      };
    },
    canvasToBlob(canvas) {
      return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("Failed to create blob."));
          }
        });
      });
    },
    async createFile() {
      const canvas = this.cropper.getCroppedCanvas();
      const img = canvas.toDataURL("image/webp");

      try {
        const blob = await this.canvasToBlob(canvas);
        const options = {
          lastModified: new Date(),
          type: blob.type,
        };

        const myFile = new File([blob], this.fileName, options);
        const dataTransfer = new DataTransfer();

        dataTransfer.items.add(myFile);
        this.changePicture({ picture: dataTransfer.files[0], pictureUrl: img, fileName: this.fileName });
      } catch (error) {
        console.error("Failed to create file:", error);
      }
    },
    initCropper() {
      const vm = this;
      this.cropper = new Cropper(this.$refs.image, {
        aspectRatio: 1,
        viewMode: 3,
        autoCropArea: 1,
        zoomable: true,
        crop() {
          vm.createFile();
        },
      });
    },
  },
  beforeDestroy() {
    if (this.showCroper) {
      this.changePicture({});
    }
  },
};
</script>

<style lang="scss">
.imgInput {
  @include Flex(column, flex-start, flex-start);
  width: 100%;
  gap: $mpadding;
  &__container {
    @include Flex(row, flex-start);
    width: 100%;
    gap: $mpadding;
  }
  &__containerPicture {
    position: relative;
  }
  &__profilePhoto {
    @include Flex(row);
    width: 74px;
    height: 74px;
    text-align: center;
    font-size: 10px;
    background: #e2e2e2;
    border-radius: 50%;
    cursor: pointer;
    z-index: 20;
  }
  &__profileInput {
    position: absolute;
    display: none;
  }
  &__photoName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }
  &__cropperContainer {
    @include Flex(column, flex-start, center);
    width: 250px;
    gap: $mpadding;
  }
  &__cropperImg {
    height: 250px;
    width: 100%;
    overflow: hidden;
  }
  &__cropperButtons {
    @include Flex(row);
    gap: $mpadding;
  }
  &__cropperButton {
    padding: 3px 8px;
    width: 112px;
    font-weight: 600;
    color: $primary-color;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid $primary-color;
    &--select {
      background: $primary-color;
      color: #ffffff;
    }
  }
}
</style>
